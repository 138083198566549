import React, { useEffect, useState } from 'react';

import ReactApexChart from 'react-apexcharts';
import './ApexChart.css';


function ApexChart({ seriesData, width, height }) {
  const [dataSeries, setDataSeries] = useState([]);
  const [chartData, setChartData] = useState([]);
  // const [seriesData, setSeriesData] = useState(seriesData);

  // const fetchLotteryRewardResult = async (getDate) => {
  //   try {
  //     const requestBody = {
  //       lotteryId: '6505f82935d1e4e1ae1ae3a1',
  //       date: getDate,
  //     };

  //     const response = await axios.post(
  //       `${API_URL}/lottery-reward-result-nk`,
  //       requestBody
  //     );

  //     // Check if the request was successful
  //     if (response.status === 200) {
  //       console.log('response.data : ', response.data.seriesData);

  //       setSeriesData(response.data.seriesData);
  //     } else {
  //       console.error(
  //         'API request failed:',
  //         response.status,
  //         response.statusText
  //       );
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error(
  //       'An error occurred while fetching data from the API:',
  //       error
  //     );
  //     return null;
  //   }
  // };

  // useEffect(() => {
  //   const now = new Date();
  //   const months = [
  //     '01',
  //     '02',
  //     '03',
  //     '04',
  //     '05',
  //     '06',
  //     '07',
  //     '08',
  //     '09',
  //     '10',
  //     '11',
  //     '12',
  //   ];

  //   const year = now.getFullYear();
  //   const month = months[now.getMonth()];
  //   const day = String(now.getDate()).padStart(2, '0');

  //   const sendBodyDate = `${year}-${month}-${day}`;
  //   fetchLotteryRewardResult(sendBodyDate);
  // }, []);

  useEffect(() => {
    // When seriesData changes, update dataSeries
    // Ensure that the timestamp values are converted to numbers
    const formattedData = seriesData.map((dataPoint) => ({
      x: Number(dataPoint.x), // Convert to number
      y: dataPoint.y,
    }));
    setDataSeries(formattedData);
  }, [seriesData]);

  useEffect(() => {
    // Whenever dataSeries changes, update chartData
    setChartData([
      {
        series: [
          {
            name: 'Price',
            data: dataSeries, // Use dataSeries here
          },
        ],
        options: {
          chart: {
            id: 'chart4',
            group: 'social',
            type: 'area',
            height: height,
            width: width,
          },
          colors: ['#008FFB'],
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            type: 'datetime', // Use datetime type for x-axis
            labels: {
              style: {
                colors: 'white',
              },
            },
            tooltip: {
              enabled: false, // Disable tooltip for x-axis
            },
          },
          yaxis: {
            opposite: true,
            labels: {
              style: {
                colors: 'white',
              },
              formatter: function (val) {
                // Round the value to two decimal places
                const roundedValue = Math.round(val * 100) / 100;
                // Convert the rounded value to a string and remove trailing ".00"
                const formattedValue = roundedValue
                  .toFixed(2)
                  .replace(/\.00$/, '');
                // Use commas for thousands separator
                return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              },
            },
          },
          tooltip: {
            theme: 'dark',
            style: {
              fontSize: '14px',
            },
            x: {
              format: 'HH:mm', // Format tooltip x value as HH:mm
            },
          },
        },
      },
    ]);
  }, [dataSeries]);

  return (
    <div id='wrapper' className='chart-container'>
      {/* Add a CSS class */}
      {chartData.map((chart, index) => (
        <div key={index} className='chart-section'>
          <ReactApexChart
            options={chart.options}
            // series={chart.series}
            series={[
              // Create a series with a name and the actual data points
              {
                name: 'Price',
                data: dataSeries,
              },
            ]}
            type={chart.options.chart.type}
            height={chart.options.chart.height}
            width={chart.options.chart.width}
          />
        </div>
      ))}
    </div>
  );
}

export default ApexChart;
